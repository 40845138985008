<template>
    <div :id="uid"></div>
</template>

<script setup>
import 'swagger-ui/dist/swagger-ui.css'
import SwaggerUI from 'swagger-ui'
import SwaggerUIStandalonePreset from 'swagger-ui/dist/swagger-ui-standalone-preset'
const props = defineProps({
    swaggerUrl: {
        type: String,
        required: true
    },
    uid: String
})

onMounted(() => {
    SwaggerUI({
        url: props.swaggerUrl,
        tryItOutEnabled: true,
        docExpansion: 'full',
        dom_id: '#' + props.uid,
        presets: [
            SwaggerUI.presets.apis,
            SwaggerUIStandalonePreset.slice(1),
        ],
        layout: 'StandaloneLayout',
    })

})
</script>

<style>
.swagger-ui .description,
.swagger-ui .info {
    display: none;
}

.swagger-ui .scheme-container {
    display: none;
}

.swagger-ui .try-out {
    display: none;
}

.swagger-ui .swagger-content-container {
    margin-bottom: 2rem;
}

.swagger-ui .version-stamp {
    display: none !important;
}

.swagger-ui .opblock-section-header {
    display: none;
}

.swagger-ui .parameter__in {
    display: none;
}

.swagger-ui .required::after {
    display: none;
}

.swagger-ui .opblock-section-header {
    display: none !important;
}

.swagger-ui .opblock-tag {
    display: none !important;
}

.swagger-ui .opblock-description-wrapper {
    padding: 5px 20px;
}

.swagger-ui .table-container {
    padding: 5px 20px;
}
</style>
